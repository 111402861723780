<template>
  <v-card class="rounded-card" flat outlined>
    <slot name="title">
      <v-card-title v-if="title" v-text="$t(title)" />
    </slot>
    <v-list dense flat class="py-0">
      <template v-for="(item, index) in items">
        <v-divider v-if="index !== 0" :key="index" :inset="true" class="my-0" />
        <v-list-item :key="item.title">
          <v-list-item-content>
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4" xl="4">
                <v-list-item-subtitle class="font-weight-bold overline" v-html="$t(item.key)" />
              </v-col>
              <v-col cols="12" sm="8" md="8" lg="8" xl="8">
                <div v-if="item.phone && item.value">
                  <vue-tel-input-vuetify v-model="item.value" :outline="true" :rounded="true" disabled :dense="true"
                  :disabledFetchingCountry="true" label='' />
                </div>
                <div v-else>
                  <v-list-item-title class="font-weight-medium" v-html="item.value"  />
                </div>
              </v-col>
            </v-row>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: 'InformationCard',
  props: {
    title: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    }
  }
};
</script>
